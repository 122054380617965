export { matchers } from "/.svelte-kit/generated/client/matchers.js";

export const nodes = [
	() => import("/.svelte-kit/generated/client/nodes/0.js"),
	() => import("/.svelte-kit/generated/client/nodes/1.js"),
	() => import("/.svelte-kit/generated/client/nodes/2.js"),
	() => import("/.svelte-kit/generated/client/nodes/3.js"),
	() => import("/.svelte-kit/generated/client/nodes/4.js"),
	() => import("/.svelte-kit/generated/client/nodes/5.js"),
	() => import("/.svelte-kit/generated/client/nodes/6.js"),
	() => import("/.svelte-kit/generated/client/nodes/7.js"),
	() => import("/.svelte-kit/generated/client/nodes/8.js"),
	() => import("/.svelte-kit/generated/client/nodes/9.js"),
	() => import("/.svelte-kit/generated/client/nodes/10.js"),
	() => import("/.svelte-kit/generated/client/nodes/11.js"),
	() => import("/.svelte-kit/generated/client/nodes/12.js"),
	() => import("/.svelte-kit/generated/client/nodes/13.js"),
	() => import("/.svelte-kit/generated/client/nodes/14.js"),
	() => import("/.svelte-kit/generated/client/nodes/15.js"),
	() => import("/.svelte-kit/generated/client/nodes/16.js"),
	() => import("/.svelte-kit/generated/client/nodes/17.js"),
	() => import("/.svelte-kit/generated/client/nodes/18.js"),
	() => import("/.svelte-kit/generated/client/nodes/19.js")
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/aboutus": [3],
		"/cargoinsurance": [4],
		"/contactusform": [5],
		"/footer": [6],
		"/getaquote": [7],
		"/header1": [9],
		"/header": [8],
		"/healthcareform": [10],
		"/homepageresp": [11],
		"/landscapewarning": [12],
		"/lifeform": [13],
		"/notes": [14],
		"/productdetails": [15],
		"/products": [16],
		"/scrollbar": [17],
		"/smallcardcatalog": [18],
		"/test": [19]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from "/.svelte-kit/generated/root.svelte";